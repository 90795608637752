// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditStickyBarScss from "../../ProviderEditStickyBar.scss";

var css = ProviderEditStickyBarScss;

function ProviderEditApproveChanges(props) {
  var tmp;
  if (props.approved) {
    tmp = "Approved";
  } else {
    switch (props.userRole) {
      case "Admin" :
          tmp = "Approve";
          break;
      case "Visitor" :
          tmp = null;
          break;
      default:
        tmp = "Waiting Approval";
    }
  }
  return JsxRuntime.jsx(Button.make, {
              size: "MD",
              color: "Teal",
              visuallyDisabled: props.disabled,
              className: css.approveButton,
              onClick: props.approve,
              children: tmp
            });
}

var make = ProviderEditApproveChanges;

export {
  css ,
  make ,
}
/* css Not a pure module */
