// extracted by mini-css-extract-plugin
export var column = "ProviderEditProviderUsers__column__FC2gw";
export var deleteConfirmationButtons = "ProviderEditProviderUsers__deleteConfirmationButtons__Balms";
export var deleteConfirmationDialog = "ProviderEditProviderUsers__deleteConfirmationDialog__PMawl";
export var deleteConfirmationOverlay = "ProviderEditProviderUsers__deleteConfirmationOverlay__NpIzg";
export var deleteConfirmationText = "ProviderEditProviderUsers__deleteConfirmationText__SsqQR";
export var dropdown = "ProviderEditProviderUsers__dropdown__wzvzQ";
export var dropdownBody = "ProviderEditProviderUsers__dropdownBody__RbnqK";
export var dropdownBodyLink = "ProviderEditProviderUsers__dropdownBodyLink__NpE43";
export var dropdownBodyRow = "ProviderEditProviderUsers__dropdownBodyRow__p7c8b";
export var header = "ProviderEditProviderUsers__header__GbguD";
export var label = "ProviderEditProviderUsers__label__mlqyl";
export var pagination = "ProviderEditProviderUsers__pagination__bn3UU";
export var profilePic = "ProviderEditProviderUsers__profilePic__XlBuE";
export var row = "ProviderEditProviderUsers__row__bXZBe";
export var table = "ProviderEditProviderUsers__table__mxprg";