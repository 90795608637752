// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditLocationStickyBar__actionButtonsContainer__mQDVs";
export var approvalContainer = "ProviderEditLocationStickyBar__approvalContainer__nwYhp";
export var approveButton = "ProviderEditLocationStickyBar__approveButton__yml5A";
export var column = "ProviderEditLocationStickyBar__column__AC59R";
export var container = "ProviderEditLocationStickyBar__container__ZJXn4";
export var inactiveText = "ProviderEditLocationStickyBar__inactiveText__buCwJ";
export var innerContainer = "ProviderEditLocationStickyBar__innerContainer__IQaur";
export var row = "ProviderEditLocationStickyBar__row__O7P8w";
export var saveButton = "ProviderEditLocationStickyBar__saveButton__T1cm5";
export var toggleContainer = "ProviderEditLocationStickyBar__toggleContainer__a2HcA";