// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditStickyBar__actionButtonsContainer__BGYvd";
export var approvalContainer = "ProviderEditStickyBar__approvalContainer__dz5zy";
export var approveButton = "ProviderEditStickyBar__approveButton__QTTQX";
export var column = "ProviderEditStickyBar__column__jKD2k";
export var container = "ProviderEditStickyBar__container__wUoxX";
export var inactiveText = "ProviderEditStickyBar__inactiveText__jQJz8";
export var innerContainer = "ProviderEditStickyBar__innerContainer__vyxHM";
export var row = "ProviderEditStickyBar__row__QKbzS";
export var saveButton = "ProviderEditStickyBar__saveButton__RwLKl";
export var toggleContainer = "ProviderEditStickyBar__toggleContainer__b37Ys";