// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEdit__actionButtonsContainer__zuKBo";
export var column = "ProviderEdit__column__qSEIE";
export var dropdownBody = "ProviderEdit__dropdownBody__NvSOY";
export var dropdownBodyRow = "ProviderEdit__dropdownBodyRow__iPJSB";
export var dropdownTrigger = "ProviderEdit__dropdownTrigger__aXMDB";
export var inactiveText = "ProviderEdit__inactiveText___mJ3U";
export var label = "ProviderEdit__label__XX4Un";
export var layout = "ProviderEdit__layout__w24Kk";
export var row = "ProviderEdit__row__o4TC1";
export var searchField = "ProviderEdit__searchField__kkUrZ";
export var searchIcon = "ProviderEdit__searchIcon__DhkdL";
export var toggleContainer = "ProviderEdit__toggleContainer__lpyIK";